/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import React, {useEffect, useState} from 'react';
import {renderAlert} from "../_utilities/formattingUtils";
import useCompanyInfo from "../_hooks/useCompanyInfo";
import AddCompanyForm from "./_ui/formElements/AddCompanyForm";
import AddCompanyContactsForm from "./_ui/formElements/AddCompanyContactsForm";
import Welcome from "./Welcome";

const Register = () => {
    const company = useCompanyInfo();
    const [step, setStep] = useState(1);
    const [display, setDisplay] = useState(<AddCompanyForm submitHandler={(newCompany) => {company.setInfo(newCompany); setStep(2);}} buttonLabel={`Continue to Contacts`} />)
    const [error] = useState(null);

    useEffect(() => {
        console.log('step update',step);
        switch(step){
            case 1:
                setDisplay(<AddCompanyForm submitHandler={(newCompany)=>{
                    company.setInfo(newCompany);
                    setStep(2);
                }} buttonLabel={`Continue to Contacts`} />);
                break;
            case 2:
                setDisplay(<AddCompanyContactsForm submitHandler={()=>{
                    setStep(3);
                }} />);
                break;
            case 3:
                setDisplay(<Welcome/>);
                break;
            default:
                // do nothing
        }
    }, [step]);


    return error ? <div className="py-2 px-3 m-3">{renderAlert(error, 'danger')}</div> : (
        <div className={`py-2 px-3 m-3 mx-auto`} style={{width: '90%', maxWidth:'800px'}}>
            <h2 className={`text-center`}>Register for User X Support</h2>
            <h4 className={`text-center`} style={{color:'#999'}}>
                <span className={step===1 ? `text-dark` : ''}>Company Info</span>
                &nbsp;&nbsp;<i className={`bi bi-arrow-right`}></i>&nbsp;&nbsp;
                <span className={step===2 ? `text-dark` : ''}>Contacts</span>
                &nbsp;&nbsp;<i className={`bi bi-arrow-right`}></i>&nbsp;&nbsp;
                <span className={step===3 ? `text-dark` : ''}>Welcome</span>
            </h4>
            <hr/>
            {display}
        </div>
    );
};

export default Register;