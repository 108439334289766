/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import StatBlock from "./_ui/StatBlock";
import {formatCurrency} from "../_utilities/formattingUtils";
import AddTicketForm from "./_ui/formElements/AddTicketForm";
import TicketItem from "./tickets/TicketItem";
import {getUID} from "bootstrap/js/src/util";
import useLocalStorage from "../_hooks/useLocalStorage";
import useInterval from "../_hooks/useInterval";
import {UserContext} from "../_contexts/UserContext";
import {STEP_VALUE, TICKET_STATUS} from "../system/defaults";
import supportAPI from "../_apis/supportAPI";

const ClientDashboard = () => {
    const {userContext} = useContext(UserContext);
    const [refreshInterval, setRefreshInterval] = useState()
    const [showAdd,setShowAdd] = useState(false);
    const creditTotal = useRef(0);
    const monthTotal = useRef(0);
    const ytdTotal = useRef(0);
    const [ticketList, setTicketList] = useLocalStorage('ticketList', []);

    useInterval(()=>{
        supportAPI.get(`/company/${userContext.contact.company[0].company_id}/tickets`).then(response => {
            if(response.data && response.data.length > 0){
                setTicketList(()=>response.data.filter(ticket => ticket.status_id < 5));
            }
        });
        supportAPI.get(`/company/${userContext.contact.company[0].company_id}/currenttime`).then(response => {
            console.log('ClientDashboard::useInterval::currenttime',response);
            const credit = userContext.contact.company[0].credit ? parseInt(userContext.contact.company[0].credit.toString()) : 0; // 25
            const current = response.data.total_time ? parseInt(response.data.total_time.toString()) : 0; // 50
            const calcCurrent = current > credit ? current - credit : 0;
            const calcCredit = credit > current ? credit - current : 0;
            creditTotal.current = calcCredit * STEP_VALUE;
            monthTotal.current = calcCurrent * STEP_VALUE;
        });
        supportAPI.get(`/company/${userContext.contact.company[0].company_id}/ytdtime`).then(response => {
            console.log('ClientDashboard::useInterval::ytdtime',response);
            ytdTotal.current = response.data.total_time ? parseInt(response.data.total_time.toString()) * STEP_VALUE : 0;
        });
    }, !showAdd ? 1000 : null);

    return (
        <>
            <div className={`row border-bottom`}>
                <StatBlock title={`Active`} number={ticketList.length}
                           classes={`border-bottom border-end bg-secondary text-white`}/>
                <StatBlock title={`Credit Total`} number={formatCurrency(creditTotal.current)}
                           classes={`border-bottom border-end bg-secondary text-white`}/>
                <StatBlock title={`Current Total`} number={formatCurrency(monthTotal.current)}
                           classes={`border-bottom border-end bg-secondary text-white`}/>
                <StatBlock title={`YTD Total`} number={formatCurrency(ytdTotal.current)}
                           classes={`border-bottom bg-secondary text-white`}/>
            </div>
            <div className={`row m-3 p-3`}>
                <div className={`col-xs-12 col-md-3 mb-3`}>
                    <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                        <h3>{TICKET_STATUS[0].name}</h3>
                        <button type="button" className="btn btn-primary mb-3 border-0" data-toggle="modal"
                                onClick={()=>setShowAdd(()=>true)}
                                data-target=".bd-example-modal-lg">
                            <i className={`bi bi-plus-circle`}/> ADD NEW TICKET
                        </button>
                        <div style={{flexGrow:'1',overflowY:'auto'}}>
                            {ticketList.filter(item => item.status_id===TICKET_STATUS[0].id.toString()).map(item => <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                        </div>
                    </div>
                </div>
                <div className={`col-xs-12 col-md-3 mb-3`}>
                    <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                        <h3>{TICKET_STATUS[1].name}</h3>
                        {ticketList.filter(item => item.status_id===TICKET_STATUS[1].id.toString()).map(item => <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                    </div>
                </div>
                <div className={`col-xs-12 col-md-3 mb-3`}>
                    <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                        <h3>{TICKET_STATUS[2].name}</h3>
                        {ticketList.filter(item => item.status_id === TICKET_STATUS[2].id.toString()).map(item => <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                    </div>
                </div>
                <div className={`col-xs-12 col-md-3`}>
                    <div className={`p-3 m-1 bg-white rounded shadow-sm h-100`}>
                        <h3>{TICKET_STATUS[3].name}</h3>
                        {ticketList.filter(item => item.status_id >= TICKET_STATUS[3].id.toString()).map(item => <TicketItem key={getUID('ticket-')} ticket={item}/>)}
                    </div>
                </div>
            </div>

            {showAdd ? (
            <div className="ux-modal">
                    <div className="ux-modal-content bg-white rounded shadow-lg p-3 position-relative">
                        <h2>Add Ticket</h2>
                        <i className={`bi bi-x-circle-fill`}
                           style={{position:'absolute',top:'5px',right:'10px',fontSize:'30px'}}
                           onClick={()=>setShowAdd(()=>false)}
                        ></i>
                        <AddTicketForm submitHandler={() => setShowAdd(false)} />
                    </div>
                </div>
                ) : null}
        </>
    );
};

export default ClientDashboard;