/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/23/2022
 * for support.userx.co
 */

import "react-widgets/styles.css";
import React, {useContext, useEffect, useState} from 'react';
import {TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../../system/defaults";
import {renderAlert, renderInput, renderTextarea} from "../../../_utilities/formattingUtils";
import DatePicker from "react-widgets/DatePicker";
import {addTicket} from "../../../_utilities/ticketUtils";
import {forEach} from "react-bootstrap/ElementChildren";
import {UserContext} from "../../../_contexts/UserContext";
import {addActivity} from "../../../_utilities/activityUtilities";
import MarkdownInput from "./MarkdownInput";

const AddTicketForm = ({submitHandler}) => {
    const {userContext} = useContext(UserContext);

    if(!userContext || !userContext.contact){
        window.location.href = '/login';
    }

    const [errorMsg, setErrorMsg] = useState('');
    const [formProps, setFormProps] = useState({
        contact_id: userContext.contact.contact_id,
        company_id: userContext.contact.company[0].company_id,
        assignee_id: null,
        type_id: null,
        status_id: 1,
        priority_id: null,
        title: null,
        description: null,
        date_due: null
    });

    const onSubmit = (e) => {
        e.preventDefault();

        console.log('default formProps',formProps);

        // validate form
        setErrorMsg(()=>'');
        const required = ['type_id','title','description','priority_id','company_id'];
        for (let key in formProps) {
            if (formProps.hasOwnProperty(key)) {
                if(required.includes(key) && formProps[key] === null || formProps[key] === ''){
                    setErrorMsg(()=>`The ${key} is invalid.`);
                    return false;
                }
            }
        }
        // add ticket
        addTicket(formProps).then((newTicket)=>{
            // clear form
            let elements = document.getElementById('add-ticket-form').elements
            console.log('addTicketForm elements',elements);
            for(let i = 0; i < elements.length; i++){
                switch(elements[i].type){
                    case 'text':
                    case 'textarea':
                        elements[i].value = '';
                        break;
                    case 'radio':
                        elements[i].checked = false;
                    default:
                        // do nothing
                }
            }

            addActivity({
                ticket_id: newTicket.ticket_id,
                contact_id: userContext.contact.contact_id,
                note: `New Ticket Created.`
            }).then(()=>{/* do nothing */});

            if(submitHandler) submitHandler(formProps);
        });
    }

    return (
        <form id='add-ticket-form' className="ux-form" onSubmit={onSubmit}>
            {errorMsg !== '' ? renderAlert(errorMsg,'danger') : null}
            <div className='mb-3'>
                <label htmlFor='type' className='w-100'><strong>Type</strong>
                    <div className='d-flex flex-wrap pt-2'>
                        {TICKET_TYPES.map(type => (
                            <span className='form-check' key={type.id}>
                                <label htmlFor={`type-${type.id}`} className='text-nowrap'>
                                    <input type="radio" id={`type-${type.id}`}
                                           name="type_id" value={type.id}
                                           onChange={(e) => setFormProps({...formProps,type_id: parseInt(e.target.value)})}
                                    /> {type.name}</label>
                            </span>
                        ))}
                    </div>
                </label>
            </div>
            <div className="mb-3" style={{width: '100% !important'}}>
                <label htmlFor="shortDesc" className='w-100 '><strong>Title</strong>
                    <input type="text" id="shortDesc" name="shortDesc" className="form-control"
                           onChange={(e) => setFormProps({...formProps,title: e.target.value})} />
                </label>
            </div>
            <div className="mb-3">
                <label htmlFor="longDesc" className='w-100'><strong>Description</strong> (Uses markdown)
                    <MarkdownInput changeHandler={value => setFormProps({...formProps, description: value})}/>
                </label>
            </div>
            <div className='mb-3'>
                <label htmlFor='priority' className='w-100'><strong>Priority</strong>
                    <div className='d-flex flex-wrap pt-2'>
                        {TICKET_PRIORITIES.map(priority => (
                            <div className='form-check' key={priority.id}>
                                <label htmlFor={`priority-${priority.id}`}>
                                    <input type="radio" id={`priority-${priority.id}`} name="priority_id" value={priority.id}
                                           onChange={(e)=>setFormProps({...formProps,priority_id:parseInt(e.target.value)})}
                                    /> {priority.name}</label>
                            </div>
                        ))}
                    </div>
                </label>
            </div>
            <div className="mb-3" style={{width: '100% !important'}}>
                <label htmlFor="shortDesc" className='w-100'><strong>Due Date</strong> (optional)
                    <DatePicker id='ticket-due-date' className='datepicker'
                                dropUp={true}
                                placeholder={'mm/dd/yyyy'}
                                onChange={(value) => setFormProps({...formProps, date_due: value})}
                    />
                </label>
            </div>
            <button
                className="w-100 btn btn-lg btn-primary"
                type="submit"
                style={{marginTop: '5px'}}
            >Add New Ticket
            </button>
        </form>
    );


}

export default AddTicketForm;