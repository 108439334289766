/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/21/2022
 * for support.userx.co
 */

import React from 'react';
import {useNavigate} from "react-router";

const Welcome = ()=>{
    const navigate = useNavigate();
    return (
        <div className={`py-2 px-3 m-3 mx-auto text-center`} style={{width: '90%', maxWidth:'800px'}}>
            <h1>Welcome to User X Support!</h1>
            <h5>We appreciate your business and look forward to serving you.</h5>
            <p>An email for each user you've added has been sent out. This email will include a <strong>verification link</strong> which, when clicked, will activate your account and allow access to your dashboard.</p>
        </div>
    );
};

export default Welcome;