/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../_contexts/UserContext";
import {useNavigate} from "react-router";
import supportAPI from "../_apis/supportAPI";
import {Link} from "react-router-dom";
import {renderAlert} from "../_utilities/formattingUtils";

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const {userContext,setUserContext} = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(()=>{
        if(userContext && userContext.contact && userContext.dashboard){
            console.log('LOGIN userContext',userContext);
            if(userContext.isLoggedIn) navigate(userContext.dashboard);
        }
    },[]);

    const signIn = async() => {
        try {
            const loginResponse = await supportAPI.post(`/auth/login`,JSON.stringify({username,password}));
            if(!loginResponse.data){
                throw new Error('Unknown id')
            }
            if(loginResponse.data.error){
                throw new Error(loginResponse.data.error);
            }
            return loginResponse.data;
        } catch (err) {
            console.error('FAIL',err);
            setError(err.response.data);
        }
    }

    const submitHandler = () => {
        signIn().then(contact => {
            if(contact){
                let dashboard;
                switch(parseInt(contact.group_id)){
                    case 1:
                        dashboard = '/admins';
                        break;
                    case 5:
                        dashboard = '/clients';
                        break;
                    default:
                        dashboard = '/agents';
                }
                const updatedUser = {isLoggedIn:true,contact,dashboard};
                console.log('Logged in', contact);

                setUserContext(() => updatedUser);
                navigate(dashboard);
            }
        });
    }

    return (
        <div className={`d-flex justify-content-center`}>
            <div className={`p-3 m-2 text-center`}>
                <h2>Please Log In</h2>
                {error ? <div className="py-2 px-3 m-3">{renderAlert(error, 'danger')}</div>:null}
                <input className={`form-control mx-auto mb-2`} style={{maxWidth: '200px'}}
                       type='text'
                       name='username'
                       placeholder={`username...`}
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                />
                <input className={`form-control mx-auto mb-2`} style={{maxWidth: '200px'}}
                       type='password'
                       name='password'
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                />
                <p className={`small`}><Link to={`/forgotpassword`}>Forgot your password?</Link></p>
                <input className={`form-control mx-auto btn btn-success mb-3`} style={{maxWidth: '200px'}}
                       type='button'
                       value='Sign In'
                       onClick={submitHandler}
                />
                <p>Not registered? <Link to={`/register`}>Create an account</Link>.</p>
            </div>
        </div>
    );
};

export default Login;