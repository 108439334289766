/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";
import {search} from "./reactUtils";


export const addContact = async (contact) => {
    const contactResponse = await supportAPI.post(`/contact`, JSON.stringify(contact));
    if (!contactResponse.data) {
        throw new Error('Contact not created.');
    }
    return contactResponse.data;
}

export const updateContact = async (contact) => {
    const contactResponse = await supportAPI.put(`/contact/${contact.contact_id}`, JSON.stringify(contact));
    if(!contactResponse.data){
        throw new Error('Contact not updated.');
    }
    return contactResponse.data;
}

export const fetchContact = async (contactId) => {
    const contactResponse = await supportAPI.get(`/contact/${contactId}`);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}

export const deleteContact = async(contactId) => {
    const contactResponse = await supportAPI.delete(`/contact/${contactId}`);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}

export const sendResetCode = async (contactId) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendresetcode`);
    if (!contactResponse.data) {
        throw new Error('Reset code not sent.')
    }
    console.log('contactUtilities::sendResetCode',contactResponse.data);
    return contactResponse.data;
}

export const sendWelcome = async (contactId) => {
    const contactResponse = await supportAPI.post(`/contact/${contactId}/sendwelcome`);
    if (!contactResponse.data) {
        throw new Error('Verification email not sent.')
    }
    console.log('contactUtilities::sendWelcome',contactResponse.data);
    return contactResponse.data;
}

export const verifyResetCode = async (resetCode) => {
    const contactResponse = await search('contact_meta',[`meta_key=reset_code`,`meta_value=${resetCode}`]);
    if (!contactResponse.data) {
        throw new Error('Contact not found.')
    }
    return contactResponse.data;
}