/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/26/2022
 * for support.userx.co
 *
 * todo: WHY? WHY? WHY doesn't the FetchActivityByTicket get the lastest records when called from handleSubmit?!
 */

import React, {useContext, useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {formatDate} from "../../_utilities/formattingUtils";
import {UserContext} from "../../_contexts/UserContext";
import {addTime, fetchTicketActivity} from "../../_utilities/ticketUtils";
import {addActivity} from "../../_utilities/activityUtilities";
import {TICKET_PRIORITIES, TICKET_TYPES} from "../../system/defaults";
import {getUID} from "bootstrap/js/src/util";
import ReactMarkdown from 'react-markdown';
import useInterval from "../../_hooks/useInterval";

const ActivityFeed = ({ticket, agentList, changeHandler}) => {
    const {userContext} = useContext(UserContext);
    const [activity, setActivity] = useState([]);
    const [showAdd, setShowAdd] = useState(true);
    const [addNote, setAddNote] = useState('');
    const [showTime, setShowTime] = useState(false);
    const [timeContact, setTimeContact] = useState(userContext.contact.contact_id);
    const [timeNote, setTimeNote] = useState('');
    const [timeHour, setTimeHour] = useState(0);
    const [timeMin, setTimeMin] = useState(0);

    useInterval(()=>{
        try {
            fetchTicketActivity(ticket.ticket_id).then(response => {
                setActivity(()=>response);
            });
        } catch (err) {
            console.error('FAIL',err);
        }
    });

    useEffect(()=>{
        if(changeHandler) changeHandler();
    },[activity]);

    const handleAddSubmit = () => {
        if (!addNote || addNote === '') {
            console.error('Ticket:handleAddSubmit - Input out of range.');
            return;
        }
        try {
            addActivity({
                ticket_id: ticket.ticket_id,
                contact_id: userContext.contact.contact_id,
                note: addNote
            }).then(response => fetchTicketActivity(response.ticket_id)).then(response => {
                setActivity(()=>response);
            });
        } catch (err) {
            console.error('FAIL',err);
        }
        setAddNote('');
    }

    const handleTimeChange = () => {
        setTimeHour(document.getElementById('time-hour').value);
        setTimeMin(document.getElementById('time-min').value);
    }
    const handleTimeSubmit = () => {
        try {
            const totalSteps = ((timeHour * 4) + (timeMin / 15));
            addTime({
                ticket_id: ticket.ticket_id,
                contact_id: timeContact,
                time: totalSteps,
                description: timeNote
            }).then(response => fetchTicketActivity(response.ticket_id)).then(response => {
                setActivity(()=>response);
            });
        } catch (err) {
            console.error('FAIL',err);
        }
        setShowTime(false);
        setShowAdd(true);

    }

    return (
        <div className='activity-wrapper d-flex flex-column h-100'>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>Recent activity...</h4>
                <span>
                    {userContext.contact.group_id <= 4 ? !showTime ? <i className='bi bi-clock mx-2' onClick={() => {setShowAdd(false); setShowTime(true);}}></i>
                        : <i className='bi bi-dash-circle-fill mx-2' onClick={() => {setShowTime(false); setShowAdd(true)}}></i> : null}
                    </span>
            </div>
            <hr/>
            <div className='activity-feed flex-grow-1 overflow-hidden'>
                {showAdd ? (
                    <>
                        <div className='d-flex align-content-stretch'>
                            <textarea id='add-activity-note' className='form-control p-3 flex-grow-1 me-3'
                                  placeholder='Add activity here...'
                                  value={addNote}
                                  onChange={(e) => setAddNote(e.target.value)}
                            >{addNote}</textarea>
                            <Button className='border-0 bg-success' style={{width: '188px'}} onClick={handleAddSubmit}>Submit Activity</Button>
                        </div>
                        <hr/>
                    </>
                ) : null}
                {userContext.contact.group_id <= 4 && showTime ? (
                    <>
                        <div className='d-flex align-content-stretch'>
                            <div className='d-flex flex-column flex-grow-1 me-3'>
                                <textarea id='time-note' className='form-control mb-3 p-3 flex-grow-1'
                                          placeholder='Describe completed work...'
                                          value={timeNote}
                                          onChange={(e) => setTimeNote(e.target.value)}
                                >{addNote}</textarea>
                                <div className='d-flex justify-content-between text-nowrap'>
                                    <label className='flex-grow-1 me-3'>
                                        <select className='mb-3 form-select' value={ticket.assignee_id} onChange={value => setTimeContact(value)}>
                                            {agentList.map(agent => {
                                                return <option key={getUID('option-')} value={agent.contact_id}>{`${agent.first_name} ${agent.last_name}`}</option>
                                            })}
                                        </select></label>
                                    <label htmlFor='time-hour' className='me-3'>
                                        <input type='number' id='time-hour' name='time-hour' className='form-control'
                                           placeholder='hour' value={timeHour} onChange={handleTimeChange} min={0} max={8}/></label>
                                    <label htmlFor='time-hour' className=''>
                                        <input type='number' id='time-min' name='time-min' className='form-control'
                                               placeholder='min' value={timeMin} onChange={handleTimeChange} min={0} max={54} step={6}/></label>
                                </div>
                            </div>
                            <Button className='border-0 bg-success'  style={{width: '150px'}}
                                    onClick={handleTimeSubmit}>Submit Time</Button>
                        </div>
                        <hr/>
                    </>
                ) : null}
                {activity && activity.length >= 1 ? activity.map((item) => {
                    return (
                        <div key={`activity-${item.activity_id}`} id={item.activity_id}
                             className=''>
                            {item.contact_id >= 1 ? <div><strong>{[item.contact.first_name, ' ', item.contact.last_name]}</strong> on {formatDate(item.date_created)} said:</div>
                            : <div><strong>User X Support</strong> on {formatDate(item.date_created)}:</div>}
                            <ReactMarkdown>{item.note}</ReactMarkdown>
                            <hr/>
                        </div>);
                }) : 'No Activity yet.'}
            </div>
        </div>
    );
};

export default ActivityFeed;




