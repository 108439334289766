/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/23/2022
 * for support.userx.co
 */

import "react-widgets/styles.css";
import React, {useState} from 'react';
import {renderAlert} from "../../../_utilities/formattingUtils";
import MarkdownInput from "./MarkdownInput";
import {addCompany} from "../../../_utilities/companyUtilities";

const AddCompanyForm = ({submitHandler, buttonLabel = 'Add Company'}) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [formProps, setFormProps] = useState({
        name: null,
        url: null,
        description: null
    });

    const onSubmit = (e) => {
        e.preventDefault();

        // validate form
        setErrorMsg(() => '');
        const required = ['name', 'url'];
        for (let key in formProps) {
            if (formProps.hasOwnProperty(key)) {
                if (required.includes(key) && formProps[key] === null || formProps[key] === '') {
                    setErrorMsg(() => `The ${key} is invalid.`);
                    return false;
                }
            }
        }
        // add company
        addCompany(formProps)
            .then((newCompany) => {
                console.log('AddCompanyForm::addCompany', newCompany);
                // clear form
                let elements = document.getElementById('add-company-form').elements
                for (let i = 0; i < elements.length; i++) {
                    switch (elements[i].type) {
                        case 'text':
                        case 'textarea':
                            elements[i].value = '';
                            break;
                        case 'radio':
                            elements[i].checked = false;
                            break;
                        default:
                        // do nothing
                    }
                }
                if (submitHandler) submitHandler(newCompany);
            })
            .catch(err => {
                console.error(err);
                switch (err.response.status) {
                    case 422:
                        setErrorMsg('A company with this URL already exists.');
                        break;
                    default:
                        setErrorMsg(err.message);
                }
            })

    }

    return (
        <form id='add-company-form' className="ux-form" onSubmit={onSubmit}>
            {errorMsg !== '' ? renderAlert(errorMsg, 'danger') : null}

            <div className="mb-3" style={{width: '100% !important'}}>
                <label htmlFor="name" className='w-100 '><strong>Company Name</strong>
                    <input type="text" id="name" name="name" className="form-control" placeholder={`Acme, LLC`}
                           onChange={(e) => setFormProps({...formProps, name: e.target.value})}/>
                </label>
            </div>
            <div className="mb-3" style={{width: '100% !important'}}>
                <label htmlFor="url" className='w-100 '><strong>Website URL</strong>
                    <input type="text" id="url" name="url" className="form-control" placeholder={`https://www.acme.com`}
                           onChange={(e) => setFormProps({...formProps, url: e.target.value})}/>
                </label>
            </div>
            <div className="mb-3">
                <label htmlFor="description" className='w-100'><strong>Description</strong> (Uses markdown)
                    <MarkdownInput changeHandler={value => setFormProps({...formProps, description: value})}/>
                </label>
            </div>
            <button
                className="w-100 btn btn-lg btn-primary"
                type="submit"
                style={{marginTop: '5px'}}
            >{buttonLabel}
            </button>
        </form>
    );


}

export default AddCompanyForm;