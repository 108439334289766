/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {search} from "../_utilities/reactUtils";
import {UserContext} from "../_contexts/UserContext";
import StatBlock from "./_ui/StatBlock";
import ListBlock from "./_ui/ListBlock";
import TicketItem from "./tickets/TicketItem";
import {formatCurrency} from "../_utilities/formattingUtils";
import {getUID} from "bootstrap/js/src/util";

const AdminDashboard = () => {
    const {userContext} = useContext(UserContext);
    const [unassigned, setUnassigned] = useState([]);
    const [overdue, setOverdue] = useState([]);
    const [myAssigned, setMyAssigned] = useState([]);

    useEffect(() => {
        console.log('UserContext', userContext);
        search('ticket', [`assignee_id IS NULL`, `status_id < 5`], 'AND', 'priority_id ASC').then(response => {
            console.log('unassigned response', response);
            setUnassigned(response)
        });
        search('ticket', [
            `(date_due < CURDATE() OR (date_due IS NULL AND date_created < DATE_SUB(CURDATE(), INTERVAL 4 DAY)))`,
            `assignee_id IS NOT NULL`,
            `status_id < 5`
        ], 'AND', 'priority_id ASC').then(response => {
            console.log('overdue response', response);
            setOverdue(response)
        });
        search('ticket', [`assignee_id = ${userContext.contact.contact_id}`]).then(response => {
            setMyAssigned(response);
        })
    }, []);

    return (
        <div className={`d-flex flex-column h-100`}>
            <div className={`d-flex border-bottom`}>
                <StatBlock title={`Total`} number={100} classes={`border-end bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
                <StatBlock title={`Active`} number={100} classes={`border-end bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
                <StatBlock title={`Unassigned`} number={100} classes={`border-end bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
                <StatBlock title={`Current Income`} number={formatCurrency(2000)}
                           classes={`border-end bg-secondary text-white`} style={{minWidth: '20%'}}/>
                <StatBlock title={`YTD Income`} number={formatCurrency(32000)} classes={` bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
            </div>
            <div className={`flex-grow-1 align-items-stretch p-3 m-3 row`}>
                <div className={`col-xs-12 col-sm-8`}>
                    <div className={`row mb-3`}>
                        <div className={`col`} style={{maxHeight: '400px'}}>
                            <ListBlock title={`Unassigned Tickets`} classes={`bg-white`}
                                       items={unassigned.map(item => <TicketItem key={getUID('unassigned-')}
                                                                                 ticket={item}/>)}/>
                        </div>
                        <div className={`col`} style={{maxHeight: '400px'}}>
                            <ListBlock title={`Overdue Tickets`} classes={`bg-white`}
                                       items={overdue.map(item => <TicketItem key={getUID('overdue-')}
                                                                              ticket={item}/>)}/>
                        </div>
                    </div>
                    <div className={`row`}>
                        <div className={`col`} style={{maxHeight: '400px'}}>
                            <ListBlock title={`Outstanding Invoices`} classes={`bg-white`}/>
                        </div>
                        <div className={`col`} style={{maxHeight: '400px'}}>
                            <ListBlock title={`Overdue Invoices`} classes={`bg-white`}/>
                        </div>
                    </div>
                </div>
                <div className={`col-xs-12 col-sm-4`}>
                    <ListBlock title={`My Assigned Tickets`} classes={`bg-white`}
                               items={myAssigned.map(item => <TicketItem key={getUID('assigned-')} ticket={item}/>)}/>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;