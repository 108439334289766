/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/21/2022
 * for support.userx.co
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {renderAlert} from "../_utilities/formattingUtils"
import {TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../system/defaults";
import Badge from "react-bootstrap/Badge";
import {calculateTicketCost, fetchTicket, fetchTicketActivity, updateTicketField} from "../_utilities/ticketUtils";
import CompanyInfo from "./_ui/CompanyInfo";
import {UserContext} from "../_contexts/UserContext";
import {getUID} from "bootstrap/js/src/util";
import {useParams} from "react-router-dom";
import DatePicker from "react-widgets/DatePicker";
import {search} from "../_utilities/reactUtils";
import ActivityFeed from "./_ui/ActivityFeed";
import ReactMarkdown from'react-markdown';
import useInterval from "../_hooks/useInterval";
import {addActivity} from "../_utilities/activityUtilities";

const Ticket = () => {
    const params = useParams();
    const {userContext} = useContext(UserContext);
    const [ticket, setTicket] = useState(null);
    const [type, setType] = useState(null);
    const [priority, setPriority] = useState(null);
    const [assignee, setAssignee] = useState(null);
    const [status, setStatus] = useState(null);
    const [agentList, setAgentList] = useState([]);
    const [ticketCost, setTicketCost] = useState('$0.00');
    const [error, setError] = useState(null);

    useInterval(() => {
        try {
            fetchTicket(params.ticketId).then(t => setTicket(()=>t));
            search('contact',[`group_id IN (1,2,3,4)`]).then(response => {
                setAgentList(response)
            });
        } catch (err) {
            console.error('Ticket FAIL', err);
        }
    });

    useEffect(() => {
        if(ticket) {
            setType(()=>TICKET_TYPES.find(item=>parseInt(item.id) === parseInt(ticket.type_id)));
            setPriority(()=>TICKET_PRIORITIES.find(item=>parseInt(item.id) === parseInt(ticket.priority_id)));
            setStatus(()=>TICKET_STATUS.find(item=>parseInt(item.id) === parseInt(ticket.status_id)));
            if(agentList){
                setAssignee(()=>agentList.find(item=>parseInt(item.contact_id) === parseInt(ticket.assignee_id)));
            }
            calculateTicketCost(ticket).then(c => setTicketCost(()=>c));
        }
    }, [ticket,agentList]);

    const ticketChangeHandler = (field, value) => {
        try {
            console.log('Ticket::ticketChangeHandler',field,value);
            updateTicketField(ticket.ticket_id, field, value).then(response => {
                setTicket(() => response);

                // set assignee_id to null if status is changed to Unassigned
                if (field === 'status_id' && parseInt(value) <= 1) {
                    updateTicketField(response.ticket_id, 'assignee_id', 'null')
                        .then(statResponse => {
                            setTicket(() => statResponse);
                            addActivity({
                                ticket_id: ticket.ticket_id,
                                contact_id: userContext.contact.contact_id,
                                note: `Unassigned agent.`
                            }).then(response => {/* do nothing */});
                        });
                }
                // set status to Assigned if agent selected and status is Unassigned
                if (field === 'assignee_id' && value !== 'null' && parseInt(response.status_id) <= 1) {
                    updateTicketField(ticket.ticket_id, 'status_id', '2')
                        .then(statResponse => {
                            setTicket(() => statResponse);
                            addActivity({
                                ticket_id: ticket.ticket_id,
                                contact_id: userContext.contact.contact_id,
                                note: `Assigned ${agentList.filter(item => item.contact_id === value ? `${item.first_name} ${item.last_name}` : `an agent`)[0]}.`
                            }).then(response => {/* do nothing */});
                        });
                }
            });
        } catch (err) {
            console.error('ticketChangeHandler FAIL', err);
            setError(err.message());
        }
    }
    const activityChangeHandler = () => {
        if(ticket) calculateTicketCost(ticket).then(cost => setTicketCost(cost));
    }

    return error ? <div className="py-2 px-3 m-3">{renderAlert(error, 'danger')}</div> : <>
            {ticket ? <div className="flex-grow-1 d-flex h-100">
                    <div className="py-2 px-3 m-3 w-75 d-flex flex-column">
                        <h2 className="d-flex align-items-center">
                            {priority && status ? <Badge bg={priority.color} style={{fontSize: '12px'}}
                                                         title={priority.name}>{status.name}</Badge> : null}
                            <span className='px-3'>{ticket && ticket.title ? ticket.title : `Ticket #${params.id}`}</span>
                        </h2>
                        {ticket ? <ReactMarkdown>{`**${type ? type.name : null}** - ${ticket.description}`}</ReactMarkdown> : null}
                        <hr/>
                        <div className='flex-grow-1 overflow-auto'>
                            {ticket ? <ActivityFeed ticket={ticket} agentList={agentList} changeHandler={activityChangeHandler}/> : null}
                        </div>
                    </div>
                    <div className="py-2 px-3 bg-secondary bg-opacity-10 w-25 h-100">
                        {ticket ? <CompanyInfo contact_id={ticket.contact_id}/> : null}
                            <div className={`my-3`}>
                                {ticket && ticket.status_id && ticket.status_id === "4" ? <button className={`btn btn-success w-100 mb-1`}
                                        onClick={(e) => window.confirm("Please note: this will remove the ticket from your list. Continue?") ? ticketChangeHandler('status_id',"5") : null}>Mark Complete</button> : null }
                                {ticket && ticket.status_id && ticket.status_id === "4" ? <button className={`btn btn-warning w-100 mb-1`}
                                        onClick={(e) => window.confirm("Please note: this will move the ticket back to the Assigned list to be worked on. Please remember to add comments regarding additional work.") ? ticketChangeHandler('status_id',"2") : null}>Needs More Work</button> : null }
                                <button className={`btn btn-danger w-100`}
                                        onClick={(e) => window.confirm("Please note: you will still be charged form time spent on this ticket. Continue?") ? ticketChangeHandler('status_id',"6") : null}>Cancel Ticket</button>
                            </div>
                        Type:
                        <select className='mb-3 form-select' value={ticket && ticket.type_id ? ticket.type_id : ''} onChange={(e) => ticketChangeHandler('type_id',e.target.value)}>
                            {TICKET_TYPES.map(item => {
                                return <option key={getUID('option-')} value={item.id}>{item.name}</option>
                            })}
                        </select>
                        Priority:
                        <select className='mb-3 form-select' value={ticket && ticket.priority_id ? ticket.priority_id : ''} onChange={(e) => ticketChangeHandler('priority_id',e.target.value)}>
                            {TICKET_PRIORITIES.map(item => {
                                return <option key={getUID('option-')} value={item.id}>{item.name}</option>
                            })}
                        </select>
                        Due Date:
                        <DatePicker id='ticket-due-date' className={`mb-3`}
                                    dropUp={true}
                                    placeholder={'mm/dd/yyyy'}
                                    defaultValue={ticket && ticket.date_due ? ticket.date_due : ''}
                                    valueFormat={{ dateStyle: "medium" }}
                                    onChange={(value) => ticketChangeHandler('date_due',value)}
                        />
                        Status:
                        {parseInt(userContext.contact.group_id) <= 4 && agentList ?<select className='mb-3 form-select' value={ticket && ticket.status_id ? ticket.status_id : ''} onChange={(e) => ticketChangeHandler('status_id',e.target.value)}>
                            {TICKET_STATUS.map(item => {
                                return <option key={getUID('option-')} value={item.id}>{item.name}</option>
                            })}
                        </select> : <input type='text' disabled value={ticket && ticket.status_id ? TICKET_STATUS[parseInt(ticket.status_id)-1].name : ''} className='mb-3 form-control'/>}
                        Assigned Agent:
                        {parseInt(userContext.contact.group_id) <= 4 && agentList ? <select className='mb-3 form-select' value={ticket && ticket.assignee_id ? ticket.assignee_id : ''}
                                                                                            onChange={(e) => ticketChangeHandler('assignee_id',e.target.value)}>
                            <option value='null'>Select Agent...</option>
                            {agentList.map(item => {
                                return <option key={getUID('option-')} value={item.contact_id}>{`${item.first_name} ${item.last_name}`}</option>
                            })}
                        </select> : <input type='text' disabled value={assignee ? `${assignee.first_name} ${assignee.last_name}` : 'Unassigned'} className='mb-3 form-control'/>}

                        {ticketCost && (userContext.group_id === "5" || userContext.group_id === "1") ? <>
                            Current Ticket Value:<br/>
                            <span style={{fontSize: '2rem'}}>{ticketCost}</span>
                        </> : null}
                    </div>
            </div> : <div className='py-2 px-3 m-3 w-75'><Badge bg='secondary' className='my-2' style={{fontSize: '12px'}}>Loading Ticket data</Badge></div> }
        </>
}

export default Ticket;