/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 *
 * todo: Add edit and delete buttons to Contacts List
 * todo: Make Contacts List a component.
 */

import React, {useEffect, useState} from 'react';
import {addCompanyContact, updateCompanyContact} from "../../../_utilities/companyUtilities";
import useCompanyInfo from "../../../_hooks/useCompanyInfo";
import {isEmail, isPhone} from "../../../_utilities/checkers";
import {addContact, deleteContact, sendWelcome, updateContact} from "../../../_utilities/contactUtilities";

const AddCompanyContactsForm = ({submitHandler}) => {
        const company = useCompanyInfo();
        const [editData, setEditData] = useState({
            contact_id: 0,
            title: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
        });
        const [addData, setAddData] = useState({
            title: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
        });

        useEffect(() => {
            console.log('CompanyContactList::company', company);
        }, [company]);

        const validate = (data) => {
            if(data.title === '') return false;
            if(data.first_name === '') return false;
            if(data.last_name === '') return false;
            if(data.email === '' || !isEmail(data.email)) return false;
            if(data.phone === '' || !isPhone(data.phone)) return false;
            return true;
        }

        const renderContactList = () => {
            return company.contacts.map(contact => {
                if (editData.contact_id === contact.contact_id) {
                    return (
                        <tr key={`contact-${contact.contact_id}`} id={contact.contact_id}>
                            <td><input type='text' style={{width: '110px'}}
                                       name='title'
                                       value={editData.title}
                                       onChange={(e) => setEditData({...editData, title: e.currentTarget.value})}
                            /></td>
                            <td><input type='text' style={{width: '110px'}}
                                       name='first_name'
                                       value={editData.first_name}
                                       onChange={(e) => setEditData({...editData, first_name: e.currentTarget.value})}
                            /></td>
                            <td><input type='text' style={{width: '110px'}}
                                       name='last_name'
                                       value={editData.last_name}
                                       onChange={(e) => setEditData({...editData, last_name: e.currentTarget.value})}
                            /></td>
                            <td><input type='text'
                                       name='email'
                                       value={editData.email}
                                       style={!isEmail(editData.email) ? {outline: "1px solid red",width: '200px'} : {width: '200px'}}
                                       onChange={(e) => setEditData({...editData, email: e.currentTarget.value})}
                            />
                            </td>
                            <td><input type='text'
                                                             name='phone'
                                                             value={editData.phone}
                                                             style={!isPhone(editData.phone) ? {"outline": "1px solid red",width: '110px'} : {width: '110px'}}
                                                             onChange={(e) => setEditData({
                                                                 ...editData,
                                                                 phone: e.currentTarget.value
                                                             })} />
                            </td>
                            <td>{validate(editData) ? <i className='bi bi-save-fill' data-id={contact.contact_id}
                                   onClick={() => {
                                       const contactData = {
                                           contact_id: editData.contact_id,
                                           first_name: editData.first_name,
                                           last_name: editData.last_name,
                                           email: editData.email,
                                           phone: editData.phone
                                       };
                                       console.log('UPDATE CONTACT', contactData);
                                       updateContact(contactData).then(updatedContact => {
                                           const companyContactData = {
                                               company_id: company.info.company_id,
                                               contact_id: updatedContact.contact_id,
                                               title: editData.title
                                           }
                                           console.log('UPDATE COMPANY CONTACT', companyContactData);
                                           updateCompanyContact(companyContactData).then(() => {
                                               company.setContacts(company.contacts.map(contact => {
                                                   if (contactData.contact_id === contact.contact_id) {
                                                       return {...contactData, title: companyContactData.title};
                                                   }
                                                   return contact;
                                               }));
                                               setEditData(null);
                                           });
                                       });
                                   }}></i> : null}
                            </td>
                            <td><i className='bi bi-x-octagon-fill' data-id={contact.contact_id} onClick={() => {
                                setEditData(null);
                            }}></i></td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={`contact-${contact.contact_id}`}>
                            <td>{contact.title}</td>
                            <td>{contact.first_name}</td>
                            <td>{contact.last_name}</td>
                            <td>{contact.email}</td>
                            <td>{contact.phone}</td>
                            <td><i className='bi bi-pencil' data-id={contact.contact_id} onClick={(e) => {
                                setEditData({
                                    contact_id: contact.contact_id,
                                    title: contact.title,
                                    first_name: contact.first_name,
                                    last_name: contact.last_name,
                                    email: contact.email,
                                    phone: contact.phone
                                });
                            }}></i></td>
                            <td><i className='bi bi-trash' data-id={contact.contact_id} onClick={(e) => {
                                const targetId = e.currentTarget.dataset.id;
                                if (window.confirm('This will permanently delete this contact. Are you sure?')) {
                                    deleteContact(targetId).then(() => {
                                        company.setContacts((prevState => prevState.filter(item => parseInt(item.contact_id) !== parseInt(targetId))));
                                        });
                                }
                            }}></i></td>
                        </tr>
                    );
                }
            });
        };

        return (
            <>
                <p className='text-center'>Please add a point-of-contact for your account. You can add more than one, but
                    one is required.</p>
                <table className='text-nowrap list-table border bg-white w-100 mb-3 pb-3'>
                        <thead>
                        <tr className='bold'>
                            <th>Title</th>
                            <th>First</th>
                            <th>Last</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderContactList()}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td><input type='text' style={{width: '110px'}}
                                       name='title'
                                       value={addData.title}
                                       onChange={(e) => setAddData({...addData, title: e.currentTarget.value})}
                            /></td>
                            <td><input type='text' style={{width: '110px'}}
                                       name='first_name'
                                       value={addData.first_name}
                                       onChange={(e) => setAddData({...addData, first_name: e.currentTarget.value})}
                            /></td>
                            <td><input type='text' style={{width: '110px'}}
                                       name='last_name'
                                       value={addData.last_name}
                                       onChange={(e) => setAddData({...addData, last_name: e.currentTarget.value})}
                            /></td>
                            <td><input type='text'
                                       name='email'
                                       value={addData.email}
                                       style={addData.email.length > 0 && !isEmail(addData.email) ? {"outline": "1px solid red",width: '200px'} : {width: '200px'}}
                                       onChange={(e) => setAddData({...addData, email: e.currentTarget.value})}
                            />
                            </td>
                            <td><input type='text'
                                       name='phone'
                                       value={addData.phone}
                                       style={addData.phone.length > 0 && !isPhone(addData.phone) ? {"outline": "1px solid red",width: '110px'} : {width: '110px'}}
                                       onChange={(e) => setAddData({...addData, phone: e.currentTarget.value})}
                            />
                            </td>
                            <td colSpan={2}>
                                <input
                                    type='button'
                                    value='ADD'
                                    disabled={!validate(addData)}
                                    className='btn btn-success btn-sm'
                                    onClick={() => {
                                        const contactData = {
                                            first_name: addData.first_name,
                                            last_name: addData.last_name,
                                            email: addData.email,
                                            phone: addData.phone,
                                            group_id: 5,
                                            password: Date.now().toString(36) + Math.random().toString(36).substring(2)
                                        };
                                        console.log('NEW CONTACT', contactData);
                                        addContact(contactData).then(newContact => {
                                            const companyContactData = {
                                                company_id: company.info.company_id,
                                                contact_id: newContact.contact_id,
                                                title: addData.title
                                            }
                                            console.log('NEW COMPANY CONTACT', companyContactData);
                                            addCompanyContact(companyContactData).then(() => {
                                                company.setContacts([...company.contacts, {
                                                    ...newContact,
                                                    title: companyContactData.title
                                                }]);
                                                setAddData({
                                                    title: '',
                                                    first_name: '',
                                                    last_name: '',
                                                    email: '',
                                                    phone: ''
                                                });
                                                sendWelcome(newContact.contact_id).then(response => {
                                                    console.log(`Sent welcome to contact #${newContact.contact_id}.`);
                                                });
                                            });
                                        });
                                    }}/>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                <input
                    type='button'
                    className="w-100 btn btn-lg btn-primary"
                    value='Complete Registration'
                    style={{marginTop: '5px'}}
                    disabled = {company.contacts.length === 0}
                    onClick={() => {
                        console.log('Submitting Contacts', company.contacts.length);
                        submitHandler();
                    }}
                />
            </>

        );
    }
;

export default AddCompanyContactsForm;