import React, {useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {UserContext} from "../_contexts/UserContext";
import Login from "./Login";
import Header from "./_ui/Header";
import Footer from "./_ui/Footer";
import Ticket from "./Ticket";
import ClientDashboard from "./ClientDashboard";
import AgentDashboard from "./AgentDashboard";
import AdminDashboard from "./AdminDashboard";
import useLocalStorage from "../_hooks/useLocalStorage";
import Invoice from "./Invoice";
import MarkdownInput from "./_ui/formElements/MarkdownInput";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import PrivateRoute from "./Helpers/PrivateRoute";


function App() {
    const [userContext, setUserContext] = useLocalStorage('uxUser', {
        isLoggedIn: false,
        contact: null,
        dashboard: null,
        filters: []
    });
    const [clientList, setClientList] = useLocalStorage('uxClients', []);

    return (
        <React.StrictMode>
            <UserContext.Provider value={{userContext, setUserContext}}>
                <div className={`d-flex flex-column vh-100`}>
                    <Router>
                        <Header/>
                        <div className={`flex-grow-1 bg-light border-bottom-1 overflow-auto`}>
                            <Routes>
                                <Route path="/" element={<Login/>}/>
                                <Route path="login" element={<Login/>}/>
                                <Route path="register" element={<Register/>}/>
                                <Route path="verifyemail/:verificationCode" element={<VerifyEmail/>}/>
                                <Route path="forgotpassword" element={<ForgotPassword/>}/>
                                <Route path="resetpassword/:resetCode" element={<ResetPassword/>}/>
                                <Route path="admins" element={<PrivateRoute><AdminDashboard/></PrivateRoute>}/>
                                <Route path="agents" element={<PrivateRoute><AgentDashboard/></PrivateRoute>}/>
                                <Route path="agents/:agentId" element={<PrivateRoute><AgentDashboard/></PrivateRoute>}/>
                                <Route path="clients" element={<PrivateRoute><ClientDashboard/></PrivateRoute>}/>
                                <Route path="clients/:clientId" element={<PrivateRoute><ClientDashboard/></PrivateRoute>}/>
                                <Route path="clients/:clientId/invoices/:invoiceId" element={<PrivateRoute><Invoice/></PrivateRoute>}/>
                                <Route path="clients/:clientId/tickets/:ticketId" element={<PrivateRoute><Ticket/></PrivateRoute>}/>

                            </Routes>
                        </div>
                    </Router>
                    <Footer/>
                </div>
            </UserContext.Provider>
        </React.StrictMode>
    );
}

export default App;
