/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 12/4/2022
 * for userx-support
 */

import React from 'react';
import * as ReactDOM from 'react-dom';
import MarkdownIt from "markdown-it";
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const MarkdownInput = ({changeHandler})=>{

    const mdParser = new MarkdownIt();

    return (
        <MdEditor style={{ height: '200px' }}
                  renderHTML={text => mdParser.render(text)}
                  view={{menu:true, md: true, html: false}}
                  onChange={({html,text}) => changeHandler(text)}
        />
    );
}

export default MarkdownInput;