/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React, {useEffect, useState} from 'react';
import Badge from "react-bootstrap/Badge";
import {useNavigate} from "react-router";
import {TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../system/defaults";
import {removeDuplicates} from "../../_utilities/reactUtils";
import useLocalStorage from "../../_hooks/useLocalStorage";

const TableBlock = ({title, items, classes=null,style=null}) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useLocalStorage('uxCompanies', []);
    const [filters, setFilters] = useLocalStorage('uxFilters',{});

    useEffect(()=>{
        const itemCompanies = items.map(item=>item.company);
        setCompanies(itemCompanies.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === itemCompanies.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        }));
    },[items]);

    useEffect(()=>{
        console.log('filters',filters);
    },[filters]);

    const rowClickHandler = (ticket)=>{
        navigate(`/clients/${ticket.contact_id}/tickets/${ticket.ticket_id}`);
    }

    const renderRow = (ticket) => {
        const priority = TICKET_PRIORITIES.find(i => i.id === parseInt(ticket.priority_id));
        const status = TICKET_STATUS.find(i => i.id === parseInt(ticket.status_id));
        const type = TICKET_TYPES.find(i => i.id === parseInt(ticket.type_id));
        const option = <tr key={ticket.ticket_id}>
            <td style={{width:'30px'}}><input type='checkbox' name='bulk-select' value={ticket.ticket_id} /></td>
            <td onClick={()=>rowClickHandler(ticket)}>{ticket.ticket_id}</td>
            <td onClick={()=>rowClickHandler(ticket)}><Badge className='me-1' bg={priority.color} title={priority.name}>{status.name}</Badge></td>
            <td onClick={()=>rowClickHandler(ticket)}>{type.name}</td>
            <td onClick={()=>rowClickHandler(ticket)}>{ticket.title}</td>
            <td onClick={()=>rowClickHandler(ticket)}>{ticket.date_created}</td>
            <td onClick={()=>rowClickHandler(ticket)}>{ticket.date_due}</td>
            <td onClick={()=>rowClickHandler(ticket)}>{ticket.company ? ticket.company.name : null}</td>
        </tr>;
        if(!filters.companyFilter || filters.companyFilter === '-1'){
            return option;
        } else if(ticket.company_id === filters.companyFilter){
            return option;
        }
    }


    return (
        <div className={`ticket-list h-100 bg-light rounded p-3 shadow-sm flex-grow-1 d-flex flex-column ${classes}`} style={style}>
            <div className={`d-flex justify-content-between`}>
                <h3>{title}</h3>
                <select style={{height:'30px',padding:'0px 10px'}} value={filters.companyFilter ? filters.companyFilter : -1} onChange={(e)=>setFilters({...filters,companyFilter: e.target.value})}>
                    <option value={-1}>All Clients</option>
                    {companies.map(company => {
                        return <option key={company.company_id} value={company.company_id}>{company.name}</option>
                    })}
                </select>
            </div>
            <table className='list-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Submitted On</th>
                        <th>Date Due</th>
                        <th>Client</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => renderRow(item))}
                </tbody>
            </table>
        </div>
    );
}

export default TableBlock;