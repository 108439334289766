/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/25/2022
 * for support.userx.co
 */

import React, {useEffect, useRef, useState} from 'react';
import {search} from "../../_utilities/reactUtils";

const CompanyInfo = ({contact_id})=>{
    const [company, setCompany] = useState(null);
    const [contact, setContact] = useState(null);
    useEffect(()=>{
        search('company',[`primary_contact=${contact_id}`], 'AND', null,true)
            .then(response => {
                setCompany(() => response)
            });

        search('contact',[`contact_id=${contact_id}`], 'AND', null,true)
            .then(response => setContact(() => response));
    },[]);

    return ( <>
        {company || contact ? (
            <div className='bg-white p-3 mb-3 rounded shadow-sm text-center'>
                {company ? <h3 className='m-0'>{company.name}</h3> : null }
                {company ? <div><a href={company.url} target='_blank'>{company.url}</a></div> : null }
                {contact && contact.email ? <div><a href={`mailto:${contact.email}`} target='_blank'>{contact.first_name} {contact.last_name} ({contact.email})</a></div> : null }
                {contact && contact.phone ? <div><a href={`tel:${contact.phone}`} target='_blank'>{contact.phone}</a></div> : null }
            </div>
        ) : null}
    </>);
}

export default CompanyInfo;