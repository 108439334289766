/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/21/2022
 * for support.userx.co
 */

import React, {useContext, useEffect} from 'react';
import {UserContext} from "../../_contexts/UserContext";
import {useNavigate} from "react-router";

function Header() {
    const navigate = useNavigate();
    const {userContext,setUserContext} = useContext(UserContext);

    const dashboard = () => {
        navigate(userContext.dashboard);
    }

    const signOut = () => {
        setUserContext({contact:null,dashboard:null,filters:[]});
        navigate('/login');
    }

    return (
        <div id='main-header' className="d-flex justify-content-between align-items-center border-bottom p-3">
            <img className="d-block" height="50px"
                 src="https://www.whoisuserx.com/blog/wp-content/uploads/2015/09/int-logo-300x110.png"
                 alt="User X Logo"/>
            <div id='main-header-menu'>
                <span>Welcome, {userContext && userContext.contact ? `${userContext.contact.first_name} ${userContext.contact.last_name}` : 'User'}!</span>
                {userContext && userContext.dashboard ? <span> | <span className={`btn btn-small btn-secondary`} onClick={dashboard}>Dashboard</span> | <span className={`btn btn-small btn-secondary`} onClick={signOut}>Sign Out</span></span> : null}
            </div>
        </div>
    );
}

export default Header;