/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/7/2022
 * for support.userx.co
 */

import {STEP_VALUE} from "../system/defaults";
import supportAPI from "../_apis/supportAPI";
import {convertStepsToTime, formatCurrency} from "./formattingUtils";
import {addActivity} from "./activityUtilities";
import {search} from "./reactUtils";

export const addTicket = async (ticket) => {
    console.log('ticketUtils::addTicket',ticket);
    const ticketResponse = await supportAPI.post(`/ticket`, JSON.stringify(ticket));
    if (!ticketResponse.data) {
        throw new Error('Ticket not created.');
    }
    return ticketResponse.data;
}

export const fetchTicket = async (ticket_id) => {
    const ticketResponse = await supportAPI.get(`/ticket/${ticket_id}`);
    if (!ticketResponse.data) {
        throw new Error('Ticket not found.')
    }
    return ticketResponse.data;
}

export const fetchTicketList = async (currentUser, filter = null) => {
    if (!filter && currentUser.contact.group_id !== '1') {
        throw new Error('Not authorized to access complete ticket list.');
    }
    let ticketResponse;
    if (!filter) {
        ticketResponse = await supportAPI.get(`/ticket`);
    } else {
        ticketResponse = await supportAPI.post(`/search/ticket`, filter ? JSON.stringify(filter) : null);
    }
    if (!ticketResponse.data) {
        throw new Error('No tickets found.')
    }
    return ticketResponse.data;
}

export const fetchTicketActivity = async (ticket_id) => {
    if (!ticket_id || ticket_id <= 0) {
        throw new Error('Input out of range.');
    }

    try {
        const activityResponse = await search('activity', [`ticket_id=${ticket_id}`], 'AND', 'date_created DESC');
        return activityResponse;
    } catch (err) {
        console.error('FAIL', err);
    }

    return [];

}



export const updateTicketField = async (ticket_id, field, value) => {
    if (!field) {
        throw new Error('Invalid input.');
    }
    const data = {};
    data[field] = value;
    const ticketResponse = await supportAPI.put(`/ticket/${ticket_id}`, JSON.stringify(data));
    if (!ticketResponse.data) {
        throw new Error('Ticket not updated.');
    }
    return ticketResponse.data;
}

export const addTime = async (time) => {
    if ((!time || typeof time !== 'object') || (!time.contact_id || !time.time || !time.description)) {
        throw new Error('Invalid input.');
    }

    const timeResponse = await supportAPI.post(`/time`, JSON.stringify(time));
    if (!timeResponse.data) {
        throw new Error('Time not entered.');
    }

    let activityNote = `${convertStepsToTime(time.time)} added.`;
    const contactResponse = await supportAPI.get(`/contact/${time.contact_id}`);
    if (contactResponse.data) {
        activityNote = `${contactResponse.data.first_name} ${contactResponse.data.last_name} has entered ${convertStepsToTime(time.time)} - ${time.description}`
    }

    try {
        await addActivity({
            ticket_id: time.ticket_id,
            contact_id: time.contact_id,
            note: activityNote
        });
    } catch (err) {
        console.error('FAIL', err);
    }

    return timeResponse.data;
}

export const calculateTicketCost = async (ticket, raw = false) => {
    if (!ticket) throw new Error('Invalid input.');
    let total = 0;
    const timeEntriesResponse = await supportAPI.post(`/search/time`, JSON.stringify({ticket_id: ticket.ticket_id}));
    if (!timeEntriesResponse.data || timeEntriesResponse.data.length === 0) {
        throw new Error('No time entries found.');
    }
    await timeEntriesResponse.data.forEach(entry => total += parseInt(entry.time));
    if (raw) return total * STEP_VALUE;
    return formatCurrency(total * STEP_VALUE);
}

/**
 *
 * @param currentUser
 * @param raw
 * @returns {Promise<string|number>}
 *
 * @todo Figure out how to filter by month
 */
export const calculateMonthCost = async (currentUser, raw = false) => {
    if (!currentUser) return '$0.00';
    let total = 0;
    const tickets = await supportAPI.post(`/search/ticket`, JSON.stringify({contact_id: 1}));
    console.log('tickets', tickets);
    if (tickets.data) {
        tickets.data.forEach(ticket => {
            total += calculateTicketCost(ticket, true);
        });
    }

    if (raw) return total;

    return formatCurrency(total);
}

export const countUnassigned = (ticketList) => {
    return ticketList.filter(ticket => ticket.status_id === "1").length
}

export const calculateListMonthCost = async (contact_id) => {
    console.log('ticketUtils::calculateListMonthCost', contact_id);
    const response = await search('time', [`contact_id=${contact_id}`, `MONTH(date)=MONTH(now())`, `YEAR(date)=YEAR(now())`]);
    const totalTime = response.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.time);
    }, 0);
    return formatCurrency(totalTime * STEP_VALUE);
}

export const calculateListYtdCost = async (contact_id) => {
    const response = await search('time', [`contact_id=${contact_id}`, `YEAR(date)=YEAR(now())`]);
    const totalTime = response.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.time);
    }, 0);
    return formatCurrency(totalTime * STEP_VALUE);
}