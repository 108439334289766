/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for support.userx.co
 */

import React, {useContext} from 'react';
import {TICKET_PRIORITIES, TICKET_STATUS, TICKET_TYPES} from "../../system/defaults";
import Badge from "react-bootstrap/Badge";
import {formatDate} from "../../_utilities/formattingUtils";
import {useNavigate} from "react-router";
import {UserContext} from "../../_contexts/UserContext";

const TicketItem = ({ticket})=>{
    const {userContext} = useContext(UserContext);
    const navigate = useNavigate();
    const priority = TICKET_PRIORITIES.find(i => i.id === parseInt(ticket.priority_id));
    const status = TICKET_STATUS.find(i => i.id === parseInt(ticket.status_id));
    const type = TICKET_TYPES.find(i => i.id === parseInt(ticket.type_id));

    const ticketClickHandler = (ticket_id) => {

    }

    return (
        <div
            className={`list-item bg-white rounded mb-3 p-1 d-flex flex-column align-items-start ${ticket.status_id >= 5 ? `opacity-50` : null}`}
            id={ticket.ticket_id}
            key={ticket.ticket_id}
            onClick={() => navigate(`/clients/${ticket.contact_id}/tickets/${ticket.ticket_id}`)} >
                <span>
                    {priority && status ?
                        <Badge className='me-1' bg={priority.color} title={priority.name}>{status.name}</Badge> : null}
                    {type ? <Badge bg="secondary" title="type">{type.name}</Badge> : null}
                </span>
            <span>
                    <strong className="px-1">{ticket.title}</strong>
                    <span className='small text-nowrap'> - {formatDate(ticket.date_created)}</span>
                </span>
        </div>
    );
};

export default TicketItem;