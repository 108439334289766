/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../_contexts/UserContext";
import {useNavigate} from "react-router";
import supportAPI from "../_apis/supportAPI";
import {Link, useParams} from "react-router-dom";
import {fetchTicket} from "../_utilities/ticketUtils";
import {search} from "../_utilities/reactUtils";
import {renderAlert} from "../_utilities/formattingUtils";
import {isExpired} from "../_utilities/checkers";
import {fetchContact, updateContact} from "../_utilities/contactUtilities";

const ResetPassword = () => {
    const params = useParams();
    const [error, setError] = useState(null);
    const [disableButton, setDisableButton] = useState(true);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [contactId, setContactId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            search('contact_meta', [`meta_key="reset_code"`, `meta_value="${params.resetCode}"`]).then(searchResponse => {
                if (searchResponse.length === 0) {
                    setError('Reset code not found or invalid.');
                    throw new Error('Reset code not found or invalid.');
                }
                if(isExpired(searchResponse[0].date_created,1)){
                    setError('Reset code has expired.');
                    throw new Error('Reset code has expired.');
                }
                const contact = fetchContact(searchResponse[0].contact_id).then(contactResponse =>{
                    if(!contactResponse.contact_id){
                        setError('Contact no longer active.');
                        throw new Error('Contact no longer active.');
                    }
                    setContactId(contactResponse.contact_id);
                })
            });
        } catch (err) {
            console.error('ResetPassword FAIL', err);
        }
    }, []);

    useEffect(() => {
        if(password1 === password2 && password1.length >= 8){
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [password1, password2])

    const submitHandler = () => {
        if(contactId) {
            updateContact({contact_id: contactId, password: password1}).then(contactResponse => {
                navigate('/login');
            });
        }
    }

    return (
        <div className={`d-flex justify-content-center`}>
            <div className={`p-3 m-2`}>
                <div className={`text-center`}>
                <h2>Set New Password</h2>
                </div>
                {error ? <>
                    <div className="py-2 px-3 text-center">
                        <p>Oops! There seems to be a problem with your reset code:<br/><strong>{error}</strong></p>
                        <p><Link className={`btn btn-primary w-50 d-block mx-auto`} to='/forgotpassword'>Try Again</Link></p>
                        <p className='small'>If you are having trouble resetting your password please contact <a href='mailto:info@userx.co'>User X Support</a>.</p>
                    </div>
                </> : <>
                        <div className={`row mb-3`}>
                            <div className={'col-sm-6 col-xs-12'}>
                                <label htmlFor='password1'><strong>Enter New Password</strong>
                                    <input className={`form-control mx-auto`}
                                           type='password'
                                           name='password1'
                                           value={password1}
                                           onChange={(e) => setPassword1(e.target.value)}
                                    />
                                    {password1.length < 8 ? <div className={`text-danger small`}>Need {8-password1.length} more</div>
                                        : <div className={`text-success small`}>You're good!</div>}
                                </label>
                            </div>
                            <div className={'col-sm-6 col-xs-12'}>
                                <label htmlFor='password2'><strong>Confirm New Password</strong>
                                <input className={`form-control mx-auto`}
                                       type='password'
                                       name='password2'
                                       value={password2}
                                       onChange={(e) => setPassword2(e.target.value)}
                                />
                                    {password1 !== password2 ? <div className={`text-danger small`}>No match</div>
                                        : <div className={`text-success small`}>Confirmed!</div>}
                                </label>
                            </div>
                        </div>
                        <input className={`form-control d-block mx-auto btn btn-success mb-3`} style={{maxWidth: '200px'}}
                               type='button'
                               value='Save New Password'
                               disabled={disableButton}
                               onClick={submitHandler}
                        />
                    </>
                }
            </div>
        </div>
    );
};

export default ResetPassword;