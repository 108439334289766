/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/9/2022
 * for support.userx.co
 */

export const STEP_VALUE = 10;

export const MONTHS = [
    { index: 1, full: 'January', abbr: 'JAN'},
    { index: 2, full: 'February', abbr: 'FEB'},
    { index: 3, full: 'March', abbr: 'MAR'},
    { index: 4, full: 'April', abbr: 'APR'},
    { index: 5, full: 'May', abbr: 'MAY'},
    { index: 6, full: 'June', abbr: 'JUN'},
    { index: 7, full: 'July', abbr: 'JUL'},
    { index: 8, full: 'August', abbr: 'AUG'},
    { index: 9, full: 'September', abbr: 'SEP'},
    { index: 10, full: 'October', abbr: 'OCT'},
    { index: 11, full: 'November', abbr: 'NOV'},
    { index: 12, full: 'December', abbr: 'DEC'},
]

export const GROUPS = [
    {id: 1, name: 'Administrator'},
    {id: 2, name: 'Maintenance'},
    {id: 3, name: 'Development'},
    {id: 4, name: 'Marketing'},
    {id: 5, name: 'Client'},
]

export const TICKET_PRIORITIES = [
    {id: 1, name: 'Urgent', color: 'danger'},
    {id: 2, name: 'High', color: 'warning'},
    {id: 3, name: 'Medium', color: 'primary'},
    {id: 4, name: 'Low', color: 'success'}
]

export const TICKET_STATUS = [
    {id: 1, name: 'Backlog'},
    {id: 2, name: 'Assigned'},
    {id: 3, name: 'In Progress'},
    {id: 4, name: 'Testing'},
    {id: 5, name: 'Complete'},
    {id: 6, name: 'Cancelled'},
]

export const TICKET_TYPES = [
    { id: 1, name: 'Question'},
    { id: 2, name: 'Content Update'},
    { id: 3, name: 'Incident/Bug'},
    { id: 4, name: 'New Development'}
]
