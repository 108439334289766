/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 10/26/2022
 * for userx-support
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import StatBlock from "./_ui/StatBlock";
import {formatCurrency} from "../_utilities/formattingUtils";
import {UserContext} from "../_contexts/UserContext";
import {search} from "../_utilities/reactUtils";
import {useParams} from "react-router";
import TableBlock from "./_ui/TableBlock";
import supportAPI from "../_apis/supportAPI";
import {STEP_VALUE} from "../system/defaults";

const AgentDashboard = ()=>{
    const params = useParams();
    const {userContext} = useContext(UserContext);
    const agentId = params.agentId ? params.agentId : userContext.contact ? userContext.contact.contact_id : null;
    const [ticketTotal,setTicketTotal] = useState(0);
    const [overdueTotal, setOverdueTotal] = useState(0);
    const [monthTotal, setMonthTotal] = useState(0);
    const [ytdTotal, setYtdTotal] = useState(0);
    const [myAssigned, setMyAssigned] = useState([]);

    useEffect(() => {
        search('ticket', [`assignee_id = ${agentId}`,`status_id < 5`]).then(response => {
            console.log('my tickets', response);
            setMyAssigned(response);
            calculateTotals();
        });
    }, []);


    return (
        <div className={`d-flex flex-column h-100`}>
            <div className={`d-flex border-bottom`}>
                <StatBlock title={`Total`} number={ticketTotal.current} classes={`border-end bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
                <StatBlock title={`Overdue`} number={overdueTotal.current} classes={`border-end bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
                <StatBlock title={`${new Date().toLocaleString("default",{month:"long"})} Income`} number={formatCurrency(monthTotal)}
                           classes={`border-end bg-secondary text-white`} style={{minWidth: '20%'}}/>
                <StatBlock title={`YTD Income`} number={formatCurrency(ytdTotal)} classes={` bg-secondary text-white`}
                           style={{minWidth: '20%'}}/>
            </div>
            <div className={`flex-grow-1 align-items-stretch p-3 m-3`}>
                    <TableBlock title={`My Assigned Tickets`} classes={`table-mode bg-white`}
                               items={myAssigned}/>
            </div>
        </div>
    );

    function calculateTotals() {
            console.log('AgentDashboard::calculateTotals');
            supportAPI.get(`/contact/${agentId}/currenttime`).then(response => {
                console.log('Month Total', response.data);
                setMonthTotal(()=>parseInt(response.data.total_time.toString()) * STEP_VALUE)
            });
            supportAPI.get(`/contact/${agentId}/ytdtime`).then(response => setYtdTotal(()=>parseInt(response.data.total_time.toString()) * STEP_VALUE));
    }
};

export default AgentDashboard;