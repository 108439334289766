/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
import React from "react";
import {Alert} from "react-bootstrap";

/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/9/2022
 * for support.userx.co
 */

export const renderInput = (formProps) => {
    return (
        <input className="form-control mb-2 w-100"
               type={formProps.type}
               name={formProps.input.name}
               onChange={formProps.input.onChange}
               onPaste={formProps.input.onChange}
               value={formProps.input.value}
        />
    );
}

export const renderTextarea = (formProps) => {
    return (
        <textarea className="form-control mb-2"
                  name={formProps.input.name}
                  onChange={formProps.input.onChange}
                  onPaste={formProps.input.onChange}
                  value={formProps.input.value}
                  rows={8}
                  style={{height:"auto"}}
                  />
    );
}

export const renderRadios = (formProps) => {
    return formProps.input.options.map( option => {
        <label>TEST <input type='radio' key={option.id} id={option.id} name={formProps.input.name} value={option.value} /></label>
    });
}

export const renderAlert = (msg, variant = 'warning') => {
    return (<Alert key={variant} variant={variant}>{msg}</Alert>);
}

export const formatDate = (date, format) => {
    return new Date(date).toLocaleDateString('en-US');
}

export const formatCurrency = (num) => {
    num = Math.ceil(num);
    return num.toLocaleString('en-US',{
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

export const pad = (num,size) => {
    num = num.toString();
    while(num.length < size) num = "0" + num;
    return num;
}

export const convertStepsToTime = (steps) => {
    const totalMin = steps*15;
    const hours = Math.floor(totalMin/60);
    const mins = totalMin % 60;
    return `${pad(hours,2)}:${pad(mins,2)}`;
}

export const titlefySlug = (str,delimiter='_') => {
    str.replace()
}