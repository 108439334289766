/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */
/**
 * File created by John M. Woodcock < john@userx.co >
 * on 1/2/2023
 * for userx-support
 */

import supportAPI from "../_apis/supportAPI";
import {fetchContact} from "./contactUtilities";

export const addCompany = async (company) => {
    const companyResponse = await supportAPI.post(`/company`, JSON.stringify(company));
    if (!companyResponse.data) {
        throw new Error('Company not created.');
    }
    return companyResponse.data;
}
export const addCompanyContact = async (companyContact) => {
    const companyContactResponse = await supportAPI.post(`/company/${companyContact.company_id}/contacts`, JSON.stringify(companyContact));
    if (!companyContactResponse.data) {
        throw new Error('Company Contact not created.');
    }
    return companyContactResponse.data;
}
export const updateCompanyContact = async(companyContact) => {
    const companyContactResponse = await supportAPI.put(`/company/${companyContact.company_id}/contacts/${companyContact.contact_id}`, companyContact);
    if (!companyContactResponse.data) {
        throw new Error('Company contact not updated.');
    }
    return companyContactResponse.data;
}

export const fetchCompany = async (companyId) => {
    const companyResponse = await supportAPI.get(`/company/${companyId}`);
    if (!companyResponse.data) {
        throw new Error('Company not found.')
    }
    return companyResponse.data;
}

export const fetchCompanyContacts = async (companyId) => {
    const companyContactResponse = await supportAPI.get(`/company/${companyId}/contacts`);
    if (!companyContactResponse.data) {
        throw new Error('Company contacts not found.')
    }
    console.log('companyUtilities::fetchCompanyContacts', companyContactResponse.data);
    return companyContactResponse.data;
}