/**
 * Copyright (C) 2019 User X, LLC http://whoisuserx.com <info@userx.co>
 *
 * This program is the intellectual property of User X, LLC. You may
 * not redistribute and/or modify it without written consent by controlling
 * entities of User X, LLC.
 *
 * You should have received a copy of our Web Developer Agreement which
 * outlines proper use and distribution of this program. If you did not
 * please email info@userx.co to request your copy.
 */

import supportAPI from "../_apis/supportAPI";

/**
 * File created by John M. Woodcock < john@userx.co >
 * on 9/23/2022
 * for support.userx.co
 */

export const sortObjects = (obj, prop, dir = 'ASC') => {
    if (dir === 'DESC') {
        return obj.sort((a, b) => {
            if (a[prop] > b[prop]) {
                return -1;
            }
            if (a[prop] < b[prop]) {
                return 1;
            }
            return 0;
        });
    }

    return obj.sort((a, b) => {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    });
}

export const search = async (type, filters = [], operator = 'AND', orderBy = null, single = false) => {
    try {
        if (!type) {
            throw new Error('Type not specified.');
        }

        const searchData = {
            filter: filters.length > 1 ? filters.join(` ${operator} `) : filters[0]
        }

        if(orderBy) searchData.orderBy = orderBy;

        const searchResponse = await supportAPI.post(`/search/${type}`, JSON.stringify(searchData));
        if (!searchResponse.data) {
            throw new Error('No results found.');
        }

        if (single) {
            return searchResponse.data[0]
        }

        return searchResponse.data;
    } catch (err) {
        console.error('search FAIL', err);
        return [];
    }
}
